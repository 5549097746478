import Link from "next/link";

import Layout from "@/components/layout/Layout";
import PageLayout from "@/components/layout/PageLayout";

type Props = {};

export default function NotFound({}: Props) {
  return (
    <Layout title="Not Found">
      <PageLayout>
        <h1 className="text-3xl font-semibold mb-4">Not Found</h1>
        <div>We didn&rsquo;t find anything for your query.</div>
        <Link href={`/`} className="block text-sm mt-4 text-gray-400 hover:underline">
          Go back to home
        </Link>
      </PageLayout>
    </Layout>
  );
}
